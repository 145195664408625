<template>
  <div class="card-cash-bonus" :class="{ active: props.bonusInfo.status === 2, new: props.newType }">
    <div class="card-cash-bonus__status">
      <template v-if="props.newType">
        {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.cashBonus.newStatus') }}
      </template>

      <template v-else-if="props.bonusInfo.status === 2">
        {{
          getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.cashBonus.activeStatus')
        }}
      </template>

      <template v-else>
        {{
          getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.cashBonus.issuedStatus')
        }}
      </template>
    </div>

    <div class="card-cash-bonus__title">{{ props.bonusInfo.name }}</div>

    <div class="card-cash-bonus__info">
      <div class="card-cash-bonus__info-block">
        <span class="card-cash-bonus__info-label">
          <template v-if="props.bonusInfo.status === 2">
            {{
              getContent(
                globalComponentsContent,
                defaultLocaleGlobalComponentsContent,
                'bonuses.cashBonus.balanceLabel'
              )
            }}
          </template>

          <template v-else>
            {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.amountLabel') }}
          </template>
        </span>

        <span class="card-cash-bonus__info-value">
          <template v-if="props.bonusInfo.status === 2">
            {{ props.hideButton ? staticBonusBalanceValue : activeBonusBalanceValue }}
          </template>

          <template v-else>
            {{ bonusAmount }}
          </template>
        </span>
      </div>

      <div v-if="!!activateTimer || !!expiredTimer" class="card-cash-bonus__info-block">
        <span class="card-cash-bonus__info-label">
          <template v-if="expiredTimer">
            {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.expiredTimerLabel') }}
          </template>

          <template v-else>
            {{
              getContent(
                globalComponentsContent,
                defaultLocaleGlobalComponentsContent,
                'bonuses.cashBonus.activateTimerLabel'
              )
            }}
          </template>
        </span>

        <bonuses-timer :expired-at="(expiredTimer as string) || (activateTimer as string)" size="md" />
      </div>
    </div>

    <bonuses-wager-progress :bonus-info="props.bonusInfo" />

    <template v-if="!props.hideButton">
      <div v-if="props.bonusInfo.status === 4" class="card-cash-bonus__available">
        {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.availableTimerLabel') }}

        <bonuses-timer :expired-at="availableTimer as string" size="sm" />
      </div>

      <button-base v-else class="card-cash-bonus__button" type="secondary-2" size="sm" @click="clickAction">
        {{
          getContent(
            globalComponentsContent,
            defaultLocaleGlobalComponentsContent,
            `bonuses.cashBonus.${props.bonusInfo.status === 2 ? 'cancelButton' : 'activateButton'}`
          )
        }}
      </button-base>
    </template>
  </div>
</template>

<script setup lang="ts">
  import type { IPlayerBonus } from '~/core/types';

  const props = defineProps<{
    bonusInfo: IPlayerBonus;
    hideButton?: boolean;
    newType?: boolean;
  }>();

  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = useGlobalStore();
  const { getContent, formatAmount } = useProjectMethods();

  const activateTimer = computed(() => {
    if (props.bonusInfo.status === 1) return props.bonusInfo.activationExpiredAt;
    return undefined;
  });

  const expiredTimer = computed(() => {
    if (props.bonusInfo.status === 2) return props.bonusInfo.wageringExpiredAt;
    return undefined;
  });

  const walletStore = useWalletStore();
  const { activeAccount } = storeToRefs(walletStore);
  const getBonusBalanceValue = (): string => {
    const lockedBalance = activeAccount.value?.lockedBalance ?? 0;
    const bonusBalance = activeAccount.value?.bonusBalance ?? 0;
    const balance = Number((lockedBalance + bonusBalance).toFixed(2));
    return formatAmount(activeAccount.value?.currency, balance);
  };
  const staticBonusBalanceValue = getBonusBalanceValue();
  const activeBonusBalanceValue = computed<string | undefined>(getBonusBalanceValue);

  const bonusAmount = computed(() => {
    return formatAmount(props.bonusInfo.currency, props.bonusInfo.amount);
  });

  const bonusStore = useBonusStore();
  const { activePlayerBonus } = storeToRefs(bonusStore);

  const hasCancelLockBonus = computed(() => {
    return (
      !!activePlayerBonus.value &&
      activePlayerBonus.value.isBonusCancelLock &&
      activePlayerBonus.value.currentWagerPercentage > 0
    );
  });

  const availableTimer = computed(() => {
    if (props.bonusInfo.status === 4 && props.bonusInfo.issuedAt) return props.bonusInfo.issuedAt;
    return undefined;
  });

  const { openModal } = useModalStore();
  const clickAction = (): void => {
    if (hasCancelLockBonus.value) {
      openModal('bonus-cancel-lock');
      return;
    }

    if (props.bonusInfo.status === 2) {
      if (props.bonusInfo.openedTransactionsCount > 0) openModal('cancel-unsettled-bonus');
      else openModal('cancel-active-bonus');
    } else {
      openModal('change-active-bonus', { props: { bonusInfo: props.bonusInfo } });
    }
  };
</script>

<style src="~/assets/styles/components/card/cash-bonus.scss" lang="scss" />
